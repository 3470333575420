import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                resetinput: 'resetinput',
            },
            change: {
              change: 'refresh',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
    //   const items = this.$('item');
    //   var $ = jQuery;
    //   items.forEach(element => {
    //     if($(element).find('input').is(':checked')) {
    //       $(element).find('.-filtre .title').text($(element).find('input').val());
    //       $(element).find('.-filtre').addClass('is-active');
    //     }
    //   });

    //   $('body').click(function(e) {
    //     var container = $('.c-form_item-overlay');
    //     if (!container.is(e.target) && container.has(e.target).length === 0 && $('.c-button.-filtre').has(e.target).length === 0) {
    //       container.parents('.c-form_item').removeClass('is-active');
    //     }
    //   });

        if(this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
      
    }

    togglefilters(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if (item.classList.contains('is-active')) {
        item.classList.remove('is-active');
      } else {
        const items = this.$('item');
        items.forEach(element => element.classList.remove('is-active')); // close section opened
        item.classList.add('is-active');
      }
    }

    select(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);

      if($(target).find('input').is(':checked')) {
        $(item).find('.-filtre .title').text($(target).find('input').val());
        $(item).find('.-filtre').addClass('is-active');
      }
    }

    reset(e) {
        const target = e.currentTarget;
        let form = null;
        if(this.getData('target')) {
            form = $(this.getData('target'));
        }else {
            form = $(target).parents('form');
        }
        form[0].reset();
        $('input').prop('checked', false);
        $('input[type=text]').val('');
        this.refresh_form(form);
    }

    resetinput(e) {
        const target = e.currentTarget;
        let value = $(target).attr('data-value');

        $('#'+value).prop('checked', false);
        $('#'+value).val('');

        let form = null;
        if(this.getData('target')) {
            form = $(this.getData('target'));
        }else {
            form = $(target).parents('form');
        }

        this.refresh_form(form);
    }

    resetall(e) {
      $('input').prop('checked', false);
    }

    toggleSidebar(e) {
      const target = e.currentTarget;
      const side = document.getElementsByClassName(this.getData('side'))[0];
      if (side.classList.contains('is-active')) {
        side.classList.remove('is-active');
      } else {
        const items = this.$('side');
        items.forEach(element => element.classList.remove('is-active')); // close section opened
        side.classList.add('is-active');
      }
    }

    refresh() {
        let form;
        form = jQuery(this.el);
        this.refresh_form(form)

    }

    refresh_form(form) {
        
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){
                
                if($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                if($('.b-relation_pagination').length) {
                    $('.b-relation_pagination').html(response.pagination);
                }
                if($('.b-relation_number').length && response.count) {
                    $('.b-relation_number').html(response.count);
                }
                if($('.b-relation_filtres').length && response.filtres) {
                    $('.b-relation_filtres').html(response.filtres);
                }
                if($('.b-relation_tags').length) {
                    $('.b-relation_tags').html(response.tags);
                }
                // if($('.b-relation_filtres_sidebar').length) {
                //     $('.b-relation_filtres_sidebar').removeClass('is-active');
                // }
                if(response.autoscroll) {
                    setTimeout(() => {
                        t.call('update', false, 'Scroll'); // update Locomotive Scroll
                        t.call('init', false, 'Simulation');
                        t.call('scrollTo', '.b-relation_scrollto', 'Scroll');
                    }, 500);
                }
            },
        });
    }

    index(e){
      e.target.style.zIndex = '-1';
    }

    pagination(e) {
        const target = e.currentTarget;
        let paged = target.getAttribute('data-paged');
        let form= null;
        if(this.getData('target')) {
            form = $(this.getData('target'));
        }else {
            form = $(target).parents('.b-relation').find('form');
        }
        let data = form.serialize()+'&paged='+paged;
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: data,
            success: function( response ){
                
                if($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                if($('.b-relation_pagination').length) {
                    $('.b-relation_pagination').html(response.pagination);
                }
                if($('.b-relation_number').length && response.count) {
                    $('.b-relation_number').html(response.count);
                }
                // if($('.b-relation_filtres_sidebar').length) {
                //     $('.b-relation_filtres_sidebar').removeClass('is-active');
                // }
            },
        });
        setTimeout(() => {
            this.call('update', false, 'Scroll'); // update Locomotive Scroll
            this.call('scrollTo', '.b-relation_scrollto', 'Scroll');
        }, 500);
    }
}

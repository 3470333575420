import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
            }
          }
    }

    init() {

    }

    toggle() {
        const t = this;
        this.el.classList.toggle("is-active");
        setTimeout(() => {
          t.call('update', false, 'Scroll'); // update Locomotive Scroll
        }, 500);
    }
}


import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            tablet: {
              smooth: true,
              multiplier: 10,
            },
            smartphone: {
              smooth: true,
              multiplier: 10,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        if($('.b-single_contact').length) {
          const h = $('.b-single_contact').height();
          $('.c-footer').css('padding-bottom', h+'px');
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {
            // console.log(args.scroll);
        })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target, {'offset': -150});
    }

    update() {
      this.scroll.update();
    }
}

import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            //refresh: 'refresh',
            // galerie: 'toggleImage',
          }
        }

        //this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      var reload = initBOXA(this.getData('type'));
      reloadVehicleData();

      $('.apartirde').hide();

      setTimeout(() => {
        $('.apartirde').show();
        $('span[name="box-auto-rent-label"]').addClass(
          'u-uppercase u-p-small u-bold u-c-black'
        );
        $('span[name="box-auto-rent-value"]').addClass(
          'c-heading -h3 u-c-primary'
        );
        $('.buttonsimulation').addClass('c-button -primary');
        $('.box-auto-i-div').hide();
      }, 1000);

      $(document).on('click', '.box-auto-close', function () {
        $('body').css('overflow', 'auto');
      });
    }

    /*refresh(e) {
      const target = e.currentTarget;
      const id = target.getAttribute('data-id');
      var container = document.querySelector('.b-produits-listing_produits');
      var counter = document.querySelector('.b-produits-listing_counter');
      jQuery.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {'action': 'refresh_produits', 'term_id': id},
        success: function( response ){
          container.innerHTML = response.content;
          counter.innerHTML = response.count;
        },
      });

      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }*/

    
}

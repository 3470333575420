import { module } from 'modujs';
import lightGallery from 'lightgallery';
// import 'lightgallery/css/lightgallery-bundle.css';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                open: 'open',
            }
        }

        this.gallery = null;
    }

    init() {
        let id = this.$('gallery')[0].getAttribute('id');
        if(id) {
            this.gallery = lightGallery(document.getElementById(id), {
                download: false,
            });
        }
    }

    open(e) {
        let current = e.currentTarget;
        let id = current.getAttribute('data-id');
        this.gallery.openGallery(id);
    }
}